import React from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import StyledText from "components/atoms/text/StyledText";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 8px;
    padding: 6px 12px;
    width: auto;
    height: 100%;
    border-radius: 4px;
    background-color: transparent;
    transition: 100ms ease-in;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const NavItemText = styled(StyledText)`
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-Black);
`;

function PageHeaderNavItem(props) {
    return (
        <Link as={Link} to={props.linkAddress} reloadDocument={props.reloadDocument} onClick={props.onClick}>
            <Container>
                <NavItemText>{props.menuName}</NavItemText>
            </Container>
        </Link>
    );
}

export default PageHeaderNavItem;
