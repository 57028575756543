import React, { useState } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import Icon from "components/atoms/Icon";
import StyledText from "components/atoms/text/StyledText";

const Container = styled.div`
    position: absolute;
    top: 36px;
    right: 0;
    display: ${(props) => props.menuDp};
    padding: 8px;
    width: 100%;
    height: ${(props) => props.height};
    background-color: var(--color-White);
    border: solid 1px #d6e8f1;
    border-radius: 6px;
    overflow: hidden;
`;

const MenuList = styled.ul`
    margin: 0;
    padding: 0;
    width: 100%;
`;

const MenuItemWrap = styled.li`
    margin: 0 0 8px 0;
    padding: 0;
    list-style: none;

    &:last-child {
        margin: 0;
    }
`;

const MenuItem = styled.button`
    display: flex;
    align-items: center;
    padding: 0 12px;
    width: 100%;
    height: 32px;
    background-color: var(--color-White);
    border: transparent;
    border-radius: 4px;
    // transition: all 80ms ease-in;

    &:hover {
        background-color: var(--color-Key);

        p {
            color: var(--color-White);
        }
    }
`;

const MenuItemText = styled(StyledText)`
    margin: 0 0 0 12px;
    font-size: 1.4rem;
    font-weight: 500;
    color: var(--color-Black);
`;

function PageHeaderDropDownMenu(props) {
    const [hoveredIcon, setHoveredIcon] = useState(null);

    const handleIconMouseEnter = (iconName) => {
        setHoveredIcon(iconName);
    };

    const handleIconMouseLeave = () => {
        setHoveredIcon(null);
    };

    return (
        <Container menuDp={props.menuDp} height={props.height}>
            <MenuList>
                <MenuItemWrap>
                    <Link to={"/settings"}>
                        <MenuItem
                            onMouseEnter={() => handleIconMouseEnter("settings")}
                            onMouseLeave={handleIconMouseLeave}
                        >
                            <Icon
                                name={"setting"}
                                size={"12"}
                                color={hoveredIcon === "settings" ? "var(--color-White)" : "var(--color-Black)"}
                            />
                            <MenuItemText>Settings</MenuItemText>
                        </MenuItem>
                    </Link>
                </MenuItemWrap>
                <MenuItemWrap>
                    <MenuItem
                        onClick={props.logoutClick}
                        onMouseEnter={() => handleIconMouseEnter("logout")}
                        onMouseLeave={handleIconMouseLeave}
                    >
                        <Icon
                            name={"logout"}
                            size={"12"}
                            color={hoveredIcon === "logout" ? "var(--color-White)" : "var(--color-Black)"}
                        />
                        <MenuItemText>Sign Out</MenuItemText>
                    </MenuItem>
                </MenuItemWrap>
                {props.isStaff && (
                    <MenuItemWrap>
                        <MenuItem
                            onClick={(e) => props.setShowAdminChangeUserModal(true)}
                            onMouseEnter={() => handleIconMouseEnter("changeUser")}
                            onMouseLeave={handleIconMouseLeave}
                        >
                            <Icon
                                name={"user"}
                                size={"12"}
                                color={hoveredIcon === "changeUser" ? "var(--color-White)" : "var(--color-Black)"}
                            />
                            <MenuItemText>(관리자) 계정 전환</MenuItemText>
                        </MenuItem>
                    </MenuItemWrap>
                )}
            </MenuList>
        </Container>
    );
}

export default PageHeaderDropDownMenu;
