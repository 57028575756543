import React from "react";

import styled from "styled-components";

const TextDiv = styled.div`
    ${({ color }) => `
        ${color ? `color: ${color};` : ""}
    `}
`;

function StyledText({ children, ...props }) {
    return <TextDiv {...props}>{children}</TextDiv>;
}

export default StyledText;
