import React, { useEffect, useRef, useState } from "react";
import { useMutation } from "react-query";
import { Link } from "react-router-dom";

import styled, { css } from "styled-components";

import Icon from "components/atoms/Icon";
import Image from "components/atoms/Image";
import Alert from "components/atoms/alert/Alert";
import Button from "components/atoms/button/Button";
import GeneralText from "components/atoms/text/GeneralText";
import StyledText from "components/atoms/text/StyledText";

import AbstractModal from "components/page/modal/AbstractModal";
import AdminChangeUserModal from "components/page/modal/AdminChangeUserModal";
import PageHeaderDropDownMenu from "components/templates/header/PageHeaderDropDownMenu";
import PageHeaderNavItem from "components/templates/header/PageHeaderNavItem";

import { useUser } from "hooks/queries/useUser";

import Constants from "utils/constants";
import { decrypt } from "utils/crypto";
import { useDevice } from "utils/device";
import { logout } from "utils/request/auth";

const Container = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
    width: 100%;
    height: ${Constants.MOBILE_HEADER_HEIGHT}px;
    background-color: ${(props) => (props.isIndexPage ? "rgba(255, 255, 255, 0.8)" : "var(--color-White)")};
    border-bottom: ${(props) => (props.isIndexPage ? "transparent" : "solid 1px var(--color-Line)")};
    z-index: 100;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            padding: 0 16px;
            min-width: 768px;
            height: ${Constants.TABLET_OR_PC_HEADER_HEIGHT}px;
        `};

    ${(props) =>
        props.device.isPC &&
        css`
            padding: 0 16px;
        `};
`;

const LogoWrap = styled.div`
    transform: scale(0.8);

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            transform: scale(1);
        `};
`;

const HamburgerButtonWrap = styled.div`
    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: none;
        `};
`;

const NavSection = styled.div`
    display: none;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: flex;
            align-items: center;
            height: 36px;

            a {
                height: 100%;
            }
        `};
`;

const UserIdWrap = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 0 0 20px;
    padding: 0 12px;
    min-width: 200px;
    height: 32px;
    background-color: ${(props) => (props.menuOpen ? "var(--color-ButtonHover4)" : "transparent")};
    border-radius: 6px;
    transition: all 80ms ease-in;
    cursor: pointer;

    &:hover {
        background-color: var(--color-ButtonHover4);
    }
`;

const UserIdText = styled(StyledText)`
    margin: 0 12px 0 0;
    font-size: 1.6rem;
    font-weight: 500;
    color: var(--color-Black);
`;

const MobileBlankBox = styled.div`
    width: 24px;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: none;
        `};
`;

const GNBBackground = styled.div`
    position: fixed;
    top: ${Constants.MOBILE_HEADER_HEIGHT}px;
    left: 0;
    width: 100vw;
    height: calc(100vh - ${Constants.MOBILE_HEADER_HEIGHT}px);
    background-color: red;
    visibility: ${({ menuVisible }) => (menuVisible ? "visible" : "hidden")};
    opacity: ${({ menuVisible }) => (menuVisible ? "1" : "0")};
    transition: all 120ms ease-in;

    ${(props) =>
        props.device.isTabletOrPC &&
        css`
            display: none;
        `};
`;

const GNBSideMenuContainer = styled.div`
    width: 100vw;
    height: 100%;
    background-color: var(--color-White);
    transition: all 120ms ease-in-out;
`;

const MenuItem = styled.div`
    padding: 0 16px;
    width: 100%;
    height: 56px;
    background-color: var(--color-White);
`;

const MobileUserMenuWrap = styled.div`
    width: 100%;
`;

const MobileUserIDWrap = styled.div`
    display: flex;
    justify-content: center;
    padding: 16px 32px;
    width: 100%;
    border-top: solid 2px var(--color-Line);
`;

const MobileButtonStyle = {
    padding: "0 16px",
    height: "56px",
    bgColor: "transparent",
    hoverBgColor: "var(--color-ButtonHover4)",
    borderRadius: "0",
    fontSize: "regular",
    fontWeight: "600",
    fontColor: "var(--color-Black)",
};

function PageHeader({ ...props }) {
    const device = useDevice();

    const [email, setEmail] = useState(undefined);
    const [menuOpen, setMenuOpen] = useState(false);
    const [menuVisible, setMenuVisible] = useState(false);
    const [showAdminChangeUserModal, setShowAdminChangeUserModal] = useState(false);

    const dropdownRef = useRef(null);

    useEffect(() => {
        // 메뉴의 이메일은 localStorage 에서 가져옴
        // 비로그인 사용자가 랜딩페이지 접근 시 불필요한 백엔드 호출을 줄이기 위함
        const email = localStorage.getItem("email");
        if (email) {
            setEmail(decrypt(email));
        }

        // 메뉴 바깥 클릭 시 드롭다운 닫기 처리
        document.addEventListener("mousedown", handleOutsideClick);
        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    const [user, setUser] = useState(null);
    const userQuery = useUser();

    useEffect(() => {
        if (userQuery.data) {
            setUser(userQuery.data);
        }
    }, [userQuery.data]);

    const logoutMutation = useMutation(logout, {
        onSuccess: (data) => {
            localStorage.removeItem("token");
            localStorage.removeItem("email");
            window.location = "/";
        },
        onError: (e) => {
            Alert("warn", "에러", "서버에 오류가 발생하였습니다.");
        },
    });

    const handleOutsideClick = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setMenuOpen(false);
        }
        // if ()
    };

    const toggleMenuVisibility = () => {
        setMenuVisible(!menuVisible);
    };

    const handleCustomerSupportClick = () => {
        window.open("https://support.preppaper.com/", "_blank");
    };

    return (
        <Container device={device} isIndexPage={props.isIndexPage}>
            <HamburgerButtonWrap device={device}>
                <Button
                    onClick={toggleMenuVisibility}
                    onlyIcon
                    bgColor={"transparent"}
                    hoverBgColor={"var(--color-ButtonHover4)"}
                >
                    <Icon name={"hamburger"} />
                </Button>
            </HamburgerButtonWrap>
            <LogoWrap device={device}>
                <Link as={Link} to={email ? "/reference" : "/"} reloadDocument>
                    <Image name={"headerLogo"} />
                </Link>
            </LogoWrap>
            <NavSection device={device}>
                <>
                    <PageHeaderNavItem linkAddress={"/plan"} menuName={"Pricing"} />
                    <PageHeaderNavItem menuName={"Support"} onClick={handleCustomerSupportClick} />
                    {email ? (
                        <>
                            <UserIdWrap ref={dropdownRef} onClick={(e) => setMenuOpen(!menuOpen)} menuOpen={menuOpen}>
                                <UserIdText>{email}</UserIdText>
                                <Icon name={menuOpen ? "arrowUp" : "arrowDown"} color={"var(--color-SubBlack)"} />

                                <PageHeaderDropDownMenu
                                    logoutClick={(e) => logoutMutation.mutate()}
                                    menuDp={menuOpen ? "block" : "none"}
                                    height={menuOpen ? (user?.is_staff ? "132px" : "92px") : "0"}
                                    isStaff={user?.is_staff}
                                    setShowAdminChangeUserModal={setShowAdminChangeUserModal}
                                />
                            </UserIdWrap>
                        </>
                    ) : (
                        <PageHeaderNavItem linkAddress={"/login"} menuName={"Sign In"} />
                    )}
                </>
            </NavSection>
            <MobileBlankBox device={device} />
            <GNBBackground device={device} menuVisible={menuVisible}>
                <GNBSideMenuContainer>
                    <Link as={Link} to={"/plan"}>
                        <Button onlyText {...MobileButtonStyle} buttonText={"Pricing"} />
                    </Link>
                    <MenuItem onClick={handleCustomerSupportClick}>
                        <Button onlyText {...MobileButtonStyle} buttonText={"Support"} />
                    </MenuItem>
                    {email ? (
                        <>
                            <MobileUserMenuWrap>
                                <Link as={Link} to={"/settings"}>
                                    <Button onlyText {...MobileButtonStyle} buttonText={"Settings"} />
                                </Link>
                                <Button
                                    onlyText
                                    {...MobileButtonStyle}
                                    buttonText={"Sign Out"}
                                    onClick={(e) => logoutMutation.mutate()}
                                />
                            </MobileUserMenuWrap>
                            <MobileUserIDWrap>
                                <GeneralText size={"small"} fontWeight={"medium"} color={"var(--color-Black)"}>
                                    {email}
                                </GeneralText>
                            </MobileUserIDWrap>
                        </>
                    ) : (
                        <Link as={Link} to={"/login"}>
                            <Button onlyText {...MobileButtonStyle} buttonText={"Sign In"} />
                        </Link>
                    )}
                </GNBSideMenuContainer>
            </GNBBackground>
            {showAdminChangeUserModal && (
                <AbstractModal modalTitle="계정 전환" width={480} exitModal={(e) => setShowAdminChangeUserModal(false)}>
                    <AdminChangeUserModal exitModal={() => setShowAdminChangeUserModal(false)} />
                </AbstractModal>
            )}
        </Container>
    );
}

export default PageHeader;
