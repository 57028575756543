import React, { useEffect, useImperativeHandle, useRef, useState } from "react";

import styled from "styled-components";

import Alert from "components/atoms/alert/Alert";

const Container = styled.div`
    width: 100%;
`;

const Txtarea = styled.textarea`
    padding: ${(props) => props.padding || "6px 8px"};
    text-align: justify;
    width: 100%;
    max-width: 1000px;
    min-height: 8px;
    line-height: 1.5;
    font-size: ${(props) => props.fontsize || "1.4rem"};
    font-weight: ${(props) => props.fontweight || "400"};
    color: var(--color-SubBlack);
    background-color: ${({ isEditable }) => (isEditable ? "var(--color-Base1)" : "var(--color-White)")};
    border: ${({ isEditable }) => (isEditable ? "solid 1px var(--color-InputLine);" : "solid 1px var(--color-White)")};
    border-radius: 4px;
    outline: #dce1e7;
    resize: none;
    cursor: text;

    &:hover {
        background-color: ${({ isEditable }) => (isEditable ? "var(--color-Base1)" : "var(--color-Base1)")};
    }

    &::placeholder {
        color: var(--color-Grey2);
        font-weight: 400;
    }
`;

function Textarea(props, ref) {
    const [isEditable, setIsEditable] = useState(false);
    const [oldValue, setOldValue] = useState("");
    const textareaRef = useRef(null);

    const handleInputBlur = () => {
        if (props.value === "" && props.allowEmpty === false) {
            Alert("warn", "에러", "내용을 입력해주세요.", () => {
                if (textareaRef.current) {
                    textareaRef.current.focus();
                }
            });
        } else {
            props.saveAct();
            setIsEditable(false);
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            if (e.shiftKey) {
                props.onChange(e.target.value + "\n");
            } else {
                textareaRef.current.blur();
            }
            e.preventDefault();
        } else if (e.key === "Escape") {
            props.onChange({ target: { value: oldValue, name: props.name } });
            setIsEditable(false);
        }
    };

    useEffect(() => {
        if (isEditable) {
            setOldValue(props.value);
        }
    }, [isEditable]);

    useEffect(() => {
        const textarea = textareaRef.current;
        textarea.style.height = "auto";
        textarea.style.height = `${textarea.scrollHeight}px`;
    }, [props.value]);

    useImperativeHandle(ref, () => {
        return {
            focus: () => {
                setIsEditable(true);
                textareaRef.current.focus();
            },
        };
    });

    return (
        <Container onClick={(e) => setIsEditable(true)}>
            <Txtarea
                ref={textareaRef}
                isEditable={isEditable}
                readOnly={!isEditable}
                onBlur={handleInputBlur}
                onKeyDown={handleKeyDown}
                rows={1}
                fontsize={props.fontsize}
                fontweight={props.fontweight}
                padding={props.padding}
                name={props.name}
                onChange={props.onChange}
                placeholder={props.placeholder}
                value={props.value}
            />
        </Container>
    );
}

export default React.forwardRef(Textarea);
